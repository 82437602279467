import React from 'react';
import {RoutePermittedRole} from '../../shared/constants/AppEnums';

const BilletCutting = React.lazy(() => import('./BilletCutting'))
const BilletCuttingDetail = React.lazy(() => import('./BilletCutting/Detail'))
const Furnace = React.lazy(() => import('./BilletHeating/Furnace'))
const Inspection = React.lazy(() => import('./BilletHeating/Inspection'))
const Piercing = React.lazy(() => import('./Piercing'))
const Rolling = React.lazy(() => import('./Rolling'))
const Sizing = React.lazy(() => import('./Sizing'))
const CoolingBed = React.lazy(() => import('./CoolingBed'))
const Straightening = React.lazy(() => import('./Straightening'))
const PipeEnd = React.lazy(() => import('./PipeEnd'))
const FullLengthDrift = React.lazy(() => import('./FullLengthDrift'))
const Upsetting = React.lazy(() => import('./Upsetting'))
const HeatTreatment = React.lazy(() => import('./HeatTreatment'))
const HotStraightening = React.lazy(() => import('./HotStraightening'))
const Hydrotest = React.lazy(() => import('./Hydrotest'))
const CoatingMarkingPacking = React.lazy(() => import('./CoatingMarkingPacking'))
const FullLengthDriftPacking = React.lazy(() => import('./FullLengthDriftPacking'))
const Tally = React.lazy(() => import('./Tally'))
const TallyForm = React.lazy(() => import('./Tally/Form'));
const PipeLabel = React.lazy(() => import('./PipeLabel'))


export const productionPageConfigs = [
  {
    permittedRole: RoutePermittedRole.user,
    path: '/production/billetcutting',
    element: <BilletCutting />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/production/billetcutting/detail/:id',
    element: <BilletCuttingDetail />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/production/billetheating/furnace',
    element: <Furnace />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/production/billetheating/inspection',
    element: <Inspection />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/production/piercing',
    element: <Piercing />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/production/rolling',
    element: <Rolling />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/production/sizing',
    element: <Sizing />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/production/coolingbed',
    element: <CoolingBed />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/production/straightening',
    element: <Straightening />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/production/pipeend',
    element: <PipeEnd />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/production/fulllengthdrift',
    element: <FullLengthDrift />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/production/upsetting',
    element: <Upsetting />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/production/HeatTreatment',
    element: <HeatTreatment />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/production/HotStraightening',
    element: <HotStraightening />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/production/hydrotest',
    element: <Hydrotest />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/production/coating-marking-packing',
    element: <CoatingMarkingPacking />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/production/full-length-drift-packing',
    element: <FullLengthDriftPacking />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/production/tally',
    element: <Tally />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/production/tally/:id',
    element: <TallyForm />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/production/pipe-label',
    element: <PipeLabel />,
  },
];
